import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import SEO from "../components/SEO"
import { useLanguage } from "../contexts/LanguageContext"

const About = ({
  data: {
    file: {
      childImageSharp: { fluid },
    },
  },
}) => {
  const { selectedLanguage } = useLanguage() // Use the useLanguage hook
  return (
    <Layout>
      <SEO title="Pravila privatnosti" />
      <div className="img-wrapper img-wrapper-about">
        <Image Tag="div" fluid={fluid} className=" bcg bcg-about  " />
        {selectedLanguage === "EN" ? (
          <h2 className="about-header visibility-filter ">
            History, Present, Future...
          </h2>
        ) : (
          <h2 className="about-header visibility-filter ">
            Povijest, Sadašnjost, Budućnost…
          </h2>
        )}
        {selectedLanguage === "EN" ? (
          <h2 className="about-header mobile-visible">Privacy policy</h2>
        ) : (
          <h2 className="about-header mobile-visible">Pravila privatnosti</h2>
        )}
      </div>
      {selectedLanguage === "EN" ? (
        <div className="about-content mobile-visible ">
          <h4 className="rules-mobile-header">Privacy policy</h4>
          <h4 className="about-paragraph rules-mobile-paragraph">
            This Privacy Policy explains the reasons and ways in which the
            company Osijek-Koteks d.d., Osijek, Šamačka 11, OIB: 44610694500
            (hereinafter: Osijek-Koteks d.d.; Company) processes personal data.
            Osijek Koteks d.d. is focused on the protection of the personal data
            of respondents and their processing in accordance with the General
            Data Protection Regulation (GDPR) and the Law on the Implementation
            of the General Data Protection Regulation. Osijek Koteks d.d. is the
            manager of personal data processing. The company has a personal data
            protection officer who can be contacted via the e-mail address:
            zop@osijek-koteks.hr or at the address: Osijek-Koteks d.d., Osijek,
            Šamačka 11.
          </h4>
          <h4 className="rules-mobile-header">
            The purposes for which the Company processes personal data
          </h4>
          <h4 className="about-paragraph rules-mobile-paragraph">
            The company processes the personal data of the subjects because
            legal regulations require certain actions, or for fulfilling
            contractual obligations, or for taking actions before entering into
            a contract, or based on the legitimate interests of the Company,
            except when these interests are overridden by the interests or
            fundamental rights and freedoms of the individuals requiring the
            protection of personal data. If the Company cannot process personal
            data based on the aforementioned legal grounds, it will seek the
            consent of the subjects. If processing is based on consent, the
            subject has the right to withdraw consent at any time. To withdraw
            consent, the data controller must be notified at the email address
            zop@osijek-koteks.hr or at the address: Osijek-Koteks d.d., Osijek,
            Šamačka 11. The withdrawal of consent does not affect the legality
            of processing based on consent before its withdrawal. The company
            processes the personal data of the subjects if it is necessary for
            the conclusion and fulfillment of rights and obligations arising
            from law, contracts, for the purposes of improving operations, etc.
            The company usually collects personal data directly from the
            subjects or when data is collected through supervision (e.g., via
            video surveillance).
          </h4>
          <h4 className="rules-mobile-header">Categories of personal data</h4>
          <h4 className="about-paragraph rules-mobile-paragraph">
            In the process of entering into contracts or other legal
            transactions, the Company requires information such as name,
            surname, address, and Personal Identification Number (OIB) to
            fulfill obligations and services. Sometimes, the Company may ask the
            subject for additional personal information, for example, date of
            birth, gender, payment address, delivery address, financial
            information, or other data when the subject communicates with the
            Company by telephone or email. When the Company collects data
            through surveillance (e.g., via video surveillance), it does so in
            accordance with legal provisions or in justified cases for the
            purpose of protecting the personal safety of individuals and
            property.
          </h4>
          <h4 className="rules-mobile-header">Rights of respondents</h4>
          <h4 className="rules-mobile-header">
            The rights of the respondents are as follows:
          </h4>
          <h4 className="about-paragraph rules-mobile-paragraph">
            <span className="bold underline-txt">Right of access:</span> The
            subject has the right to receive from the Company confirmation as to
            whether or not their personal data are being processed, and if such
            personal data are being processed, access to those personal data.
            Information about access includes, among other things, the purposes
            of processing, the categories of personal data concerned, the
            recipients or categories of recipients to whom the personal data
            have been or will be disclosed. However, this is not an
            unconditional right, and the interests of other individuals may
            limit the subject's right to access. The subject has the right to
            receive a copy of the personal data being processed. For any further
            copies requested, the Company may charge a reasonable fee based on
            administrative costs.
            <span className="bold underline-txt">
              {" "}
              Right to rectification:
            </span>{" "}
            The subject has the right to obtain from the Company the
            rectification of inaccurate personal data concerning them. Taking
            into account the purposes of processing, the subject has the right
            to have incomplete personal data completed, among other things, by
            providing an additional statement.{" "}
            <span className="bold underline-txt">
              Right to erasure ("right to be forgotten"):
            </span>{" "}
            Under certain conditions, the respondent has the right to receive
            from the Company deletion of their personal data, and the Company is
            obliged to delete them.{" "}
            <span className="bold underline-txt">
              Right to restriction of processing:
            </span>{" "}
            Under certain conditions, the subject has the right to obtain from
            the Company a restriction of processing of their personal data. In
            such cases, the relevant data will be marked and may only be
            processed for specific purposes.
            <span className="bold underline-txt">Right to object:</span> Under
            certain conditions, the subject has the right to object at any time
            to the processing of their personal data, for reasons related to
            their particular situation, or when personal data are processed for
            direct marketing purposes. They can request the Company to stop
            processing their personal data.
            <span className="bold underline-txt">
              Right to data portability:
            </span>{" "}
            Under certain conditions, the subject has the right to receive their
            personal data that they have provided to the Company in a
            structured, commonly used, and machine-readable format, and has the
            right to transmit those data to another data controller without
            hindrance from our Company. If the subject wishes to learn more or
            wants to exercise one or more of the aforementioned rights, they can
            contact the Company at the address: Osijek-Koteks d.d., Osijek,
            Šamačka 11, or at the email address: zop@osijek-koteks.hr.
            Furthermore, if the subject believes that their rights are not being
            respected, they have the right to lodge a complaint with the
            Personal Data Protection Agency.
          </h4>
          <h4 className="rules-mobile-header">Storage of personal data</h4>
          <h4 className="about-paragraph rules-mobile-paragraph">
            The personal data collected about the subject by the Company is kept
            in a secure environment. The personal data of the subjects are
            protected from unauthorized access, disclosure, use, alteration, or
            destruction by any organization or individual. The collected
            personal data for the aforementioned purposes will be stored for the
            duration necessary for those purposes. Personal data of the subjects
            will not be kept in a form that allows them to be identified for
            longer than Osijek-Koteks d.d. reasonably considers necessary for
            achieving the purpose for which they were collected or processed.
            Osijek-Koteks d.d. will retain certain personal data for the period
            prescribed by law or another regulation that obligates the data
            controller to retain data. If the subject has given consent to the
            Company, their personal data will be processed until the withdrawal
            of consent. If a well-founded objection to the processing of
            personal data based on legitimate interest is declared, the Company
            will not process the subject's personal data in the future. If a
            judicial, administrative, or extrajudicial procedure is initiated,
            personal data may be stored until the end of such a procedure,
            including any possible period for declaring legal remedies.
            Osijek-Koteks d.d. will retain certain personal data for the period
            prescribed by law or another regulation that obligates the data
            controller to retain data.
          </h4>
          <h4 className="rules-mobile-header">Recipients of personal data</h4>
          <h4 className="about-paragraph rules-mobile-paragraph">
            The Company may share the personal information of the subjects with
            trusted business partners who maintain the IT system of the Company
            or provide services on behalf of Osijek-Koteks d.d., for example,
            for finance, advertising, payment processing, delivery, and other
            services. It is the duty of these service providers, according to
            contractual rights and obligations, to use the data entrusted to
            them only in accordance with our guidelines and exclusively for the
            purpose we have strictly determined, with the obligation to
            adequately protect them and treat them as a business secret. In some
            cases, business partners providing services on behalf of or for
            Osijek-Koteks d.d. may process the subject's data outside the
            European Union. However, the contracts the Company enters into with
            such entities obligate them to handle the data by applying specific
            security measures in accordance with the regulations applicable in
            the European Union.
          </h4>
        </div>
      ) : (
        <div className="about-content mobile-visible ">
          <h4 className="rules-mobile-header">Pravila privatnosti</h4>
          <h4 className="about-paragraph rules-mobile-paragraph">
            Ova Pravila privatnosti objašnjavaju razloge i načine na koji tvrtka
            Osijek-Koteks d.d., Osijek, Šamačka 11, OIB: 44610694500 (dalje u
            tekstu: Osijek-Koteks d.d.; Društvo) obrađuje osobne podatke. Osijek
            Koteks d.d. je usmjeren na zaštitu osobnih podataka ispitanika i
            njihovu obradu u skladu s Općom uredbom o zaštiti podataka (GDPR) i
            Zakonom o provedbi Opće uredbe o zaštiti podataka. Osijek Koteks
            d.d. je voditelj obrade osobnih podataka. Društvo ima službenika za
            zaštitu osobnih podataka kojega se može kontaktirati putem adrese
            e-pošte: zop@osijek-koteks.hr ili na adresu: Osijek-Koteks d.d.,
            Osijek, Šamačka 11.
          </h4>
          <h4 className="rules-mobile-header">
            Svrhe u koje Društvo obrađuje osobne podatke
          </h4>
          <h4 className="about-paragraph rules-mobile-paragraph">
            Osobne podatke ispitanika Društvo obrađuje zbog toga što zakonski
            propisi nalažu određena postupanja ili zbog ispunjavanja ugovornih
            obveza ili zbog poduzimanja radnji prije sklapanja ugovora ili
            temeljem legitimnih interesa Društva, osim kada su od tih interesa
            jači interesi ili temeljna prava i slobode pojedinaca koji
            zahtijevaju zaštitu osobnih podataka.Ako osobne podatke Društvo ne
            može obrađivati temeljem navedenih pravnih osnova, zatražit će
            privolu ispitanika. Ako se obrada temelji na privoli, ispitanik ima
            pravo u bilo kojem trenutku povući privolu. O povlačenju privole
            potrebno je obavijestiti voditelja obrade na adresu e-pošte
            zop@osijek-koteks.hr ili na adresu: Osijek-Koteks d.d., Osijek,
            Šamačka 11. Provedeno povlačenje privole ne utječe na zakonitost
            obrade na temelju privole prije povlačenja.Osobne podatke ispitanika
            Društvo obrađuje ako je to potrebno za sklapanje i ispunjenje prava
            i obveza koje proizlaze iz zakona, ugovora, u svrhe unaprjeđenja
            poslovanja i dr.Osobne podatke u pravilu Društvo prikuplja izravno
            od ispitanika ili kada podatke prikuplja nadzorom (npr. putem video
            nadzora).
          </h4>
          <h4 className="rules-mobile-header">Kategorije osobnih podataka</h4>
          <h4 className="about-paragraph rules-mobile-paragraph">
            Kod sklapanja ugovora, odnosno drugih pravnih poslova, Društvu su
            potrebni podaci kao što su ime, prezime, adresa, OIB, a radi
            ispunjenja obveze i usluga. Ponekad ispitanika Društvo traži i
            dodatne osobne podatke, npr. datum rođenja, spol, adresu za
            plaćanje, adresu za isporuku, financijske informacije ili neke druge
            podatke u slučaju kada ispitanik komunicira s Društvom telefonski
            ili e-poštom. Kada Društvo podatke prikuplja putem nadzora (npr.
            preko video nadzora), to radi u skladu sa zakonskim odredbama ili u
            opravdanim slučajevima u svrhu zaštite osobne sigurnosti osoba i
            imovine.
          </h4>
          <h4 className="rules-mobile-header">Prava ispitanika</h4>
          <h4 className="rules-mobile-header">Prava ispitanika su sljedeća:</h4>
          <h4 className="about-paragraph rules-mobile-paragraph">
            <span className="bold underline-txt">Pravo na pristup:</span>{" "}
            ispitanik ima pravo dobiti od Društva potvrdu o tome obrađuju li se
            njegovi osobni podaci te ako se takvi osobni podaci obrađuju,
            pristup tim osobnim podacima. Informacije o pristupu uključuju,
            između ostalog, svrhe obrade, kategorije osobnih podataka o kojima
            je riječ, primatelje ili kategorije primatelja kojima su osobni
            podaci bili ili će biti otkriveni. Međutim, to nije bezuvjetno pravo
            i interesi drugih pojedinaca mogu ograničiti pravo pristupa
            ispitanika. Ispitanik ima pravo dobiti presliku osobnih podataka
            koji se obrađuju. Za daljnje preslike koje se zatraže, Društvo može
            naplatiti razumnu naknadu na temelju administrativnih troškova.
            <span className="bold underline-txt"> Pravo na ispravak:</span>{" "}
            Ispitanik ima pravo ishoditi od Društva ispravljanje svojih netočnih
            osobnih podataka. Uzimajući u obzir svrhe obrade, ispitanik ima
            pravo dopuniti nepotpune osobne podatke, između ostalog, i davanjem
            dodatne izjave.{" "}
            <span className="bold underline-txt">
              Pravo na brisanje ("pravo na zaborav"):
            </span>{" "}
            Pod određenim uvjetima, ispitanik ima pravo ishoditi od Društva
            brisanje svojih osobnih podataka, a Društvo ih je obvezno izbrisati.{" "}
            <span className="bold underline-txt">
              Pravo na ograničenje obrade:
            </span>{" "}
            Pod određenim uvjetima, ispitanik ima pravo ishoditi od Društva
            ograničenje obrade svojih osobnih podataka. U tom će slučaju
            odgovarajući podaci biti označeni i mogu biti obrađeni samo u
            određene svrhe.
            <span className="bold underline-txt">Pravo na prigovor:</span> Pod
            određenim uvjetima, ispitanik ima pravo na prigovor u bilo kojem
            trenutku na obradu svojih osobnih podataka, iz razloga koji se
            odnose na ispitanikovu konkretnu situaciju ili kada se osobni podaci
            obrađuju u cilju izravnog marketinga te može tražiti od Društva da
            više ne obrađuje njegove osobne podatke.
            <span className="bold underline-txt">
              Pravo na prenosivost podataka:
            </span>{" "}
            Pod određenim uvjetima, ispitanik ima pravo zaprimiti svoje osobne
            podatke koje je pružio Društvu u strukturiranom, uobičajeno
            upotrebljivom i strojno čitljivom obliku i ima pravo prenijeti te
            podatke drugom voditelju obrade, bez ometanja od strane našeg
            Društva.Ako ispitanik želi saznati više ili želi koristiti jedan ili
            više prethodno navedenih prava, može kontaktirati Društvo na adresu:
            Osijek-Koteks d.d., Osijek, Šamačka 11 ili na adresu e-pošte:
            zop@osijek-koteks.hr. Također, ukoliko ispitanik smatra da se
            njegova prava ne poštuju, ima pravo podnijeti pritužbu Agenciji za
            zaštitu osobnih podataka.
          </h4>
          <h4 className="rules-mobile-header">Čuvanje osobnih podataka</h4>
          <h4 className="about-paragraph rules-mobile-paragraph">
            Osobne podatke koje prikuplja o ispitaniku, Društvo čuva u sigurnom
            okruženju. Osobni podaci ispitanika su zaštićeni od neovlaštenog
            pristupa, otkrivanja, uporabe, izmjena ili uništenja od bilo koje
            organizacije ili pojedinca. Prikupljeni osobni podaci za prethodno
            navedene svrhe bit će pohranjeni u trajanju potrebnom za navedene
            svrhe. Osobni podaci ispitanika neće se čuvati u obliku koji
            omogućuje da bude identificiran duže nego što Osijek-Koteks d.d.
            razumno smatra da je neophodno za ostvarenje svrhe za koju su
            prikupljeni ili obrađeni. Osijek-Koteks d.d. će određene osobne
            podatke čuvati u razdoblju koje propisuje zakon odnosno drugi propis
            koji obvezuje voditelja obrade na čuvanje podataka. Ako je ispitanik
            dao privolu Društvu, njegove osobne podatke Društvo će obrađivati do
            povlačenja privole. Ako izjavi osnovani prigovor na obradu osobnih
            podataka temeljem legitimnog interesa, ispitanikove osobne podatke
            Društvo neće obrađivati u budućnosti. Ako je pokrenut sudski,
            upravni ili izvansudski postupak, osobni se podaci mogu pohraniti do
            kraja takvog postupka, uključujući i moguće razdoblje za
            izjavljivanje pravnih lijekova. Osijek-Koteks d.d. će određene
            osobne podatke čuvati u razdoblju koji propisuje zakon odnosno drugi
            propis koji obvezuje voditelja obrade na čuvanje podataka.
          </h4>
          <h4 className="rules-mobile-header">Primatelji osobnih podataka</h4>
          <h4 className="about-paragraph rules-mobile-paragraph">
            Osobne informacije ispitanika Društvo može dati pouzdanim poslovnim
            partnerima koji održavaju IT sustav Društva ili pružaju usluge u ime
            tvrtke Osijek-Koteks d.d. Na primjer, za potrebe financija,
            oglašavanja, obrade plaćanja, isporuke i drugih usluga. Dužnost je
            tih pružatelja usluga, sukladno ugovornim pravima i obvezama,
            koristiti podatke koji su im povjereni samo u skladu s našim
            smjernicama i isključivo u svrhu koju smo strogo odredili uz obvezu
            da ih primjereno zaštite i smatraju poslovnom tajnom. U nekim
            slučajevima, poslovni partneri koji pružaju usluge u ime ili za
            Osijek-Koteks d.d. mogu podatke ispitanika obraditi izvan Europske
            unije. Međutim, ugovori koje Društvo sklapa s takvim subjektima
            obvezuju ih da s podacima postupaju primjenjujući posebne sigurnosne
            mjere u skladu s propisima koji se primjenjuju u Europskoj uniji.
          </h4>
        </div>
      )}
      {selectedLanguage === "EN" ? (
        <div className="about-content visibility-filter">
          <h4 className="about-paragraph">
            <h4 className="rules-header">Privacy policy</h4>
            <h5>
              This Privacy Policy explains the reasons and ways in which the
              company Osijek-Koteks d.d., Osijek, Šamačka 11, OIB: 44610694500
              (hereinafter: Osijek-Koteks d.d.; Company) processes personal
              data. Osijek Koteks d.d. is focused on the protection of the
              personal data of respondents and their processing in accordance
              with the General Data Protection Regulation (GDPR) and the Law on
              the Implementation of the General Data Protection Regulation.
              Osijek Koteks d.d. is the manager of personal data processing. The
              company has a personal data protection officer who can be
              contacted via the e-mail address: zop@osijek-koteks.hr or at the
              address: Osijek-Koteks d.d., Osijek, Šamačka 11.
            </h5>
            <h4 className="rules-header">
              The purposes for which the Company processes personal data
            </h4>
            <h5>
              The company processes the personal data of the subjects because
              legal regulations require certain actions, or for fulfilling
              contractual obligations, or for taking actions before entering
              into a contract, or based on the legitimate interests of the
              Company, except when these interests are overridden by the
              interests or fundamental rights and freedoms of the individuals
              requiring the protection of personal data. If the Company cannot
              process personal data based on the aforementioned legal grounds,
              it will seek the consent of the subjects. If processing is based
              on consent, the subject has the right to withdraw consent at any
              time. To withdraw consent, the data controller must be notified at
              the email address zop@osijek-koteks.hr or at the address:
              Osijek-Koteks d.d., Osijek, Šamačka 11. The withdrawal of consent
              does not affect the legality of processing based on consent before
              its withdrawal. The company processes the personal data of the
              subjects if it is necessary for the conclusion and fulfillment of
              rights and obligations arising from law, contracts, for the
              purposes of improving operations, etc. The company usually
              collects personal data directly from the subjects or when data is
              collected through supervision (e.g., via video surveillance).
            </h5>
            <h4 className="rules-header">Categories of personal data</h4>
            <h5>
              In the process of entering into contracts or other legal
              transactions, the Company requires information such as name,
              surname, address, and Personal Identification Number (OIB) to
              fulfill obligations and services. Sometimes, the Company may ask
              the subject for additional personal information, for example, date
              of birth, gender, payment address, delivery address, financial
              information, or other data when the subject communicates with the
              Company by telephone or email. When the Company collects data
              through surveillance (e.g., via video surveillance), it does so in
              accordance with legal provisions or in justified cases for the
              purpose of protecting the personal safety of individuals and
              property.
            </h5>
            <h4 className="rules-header">Rights of respondents</h4>
            <h5>The rights of the respondents are as follows:</h5>
            <h5>
              <span className="bold underline-txt">Right of access:</span> The
              subject has the right to receive from the Company confirmation as
              to whether or not their personal data are being processed, and if
              such personal data are being processed, access to those personal
              data. Information about access includes, among other things, the
              purposes of processing, the categories of personal data concerned,
              the recipients or categories of recipients to whom the personal
              data have been or will be disclosed. However, this is not an
              unconditional right, and the interests of other individuals may
              limit the subject's right to access. The subject has the right to
              receive a copy of the personal data being processed. For any
              further copies requested, the Company may charge a reasonable fee
              based on administrative costs.
            </h5>
            <h5>
              <span className="bold underline-txt">
                Right to rectification:
              </span>{" "}
              The subject has the right to obtain from the Company the
              rectification of inaccurate personal data concerning them. Taking
              into account the purposes of processing, the subject has the right
              to have incomplete personal data completed, among other things, by
              providing an additional statement.
            </h5>
            <h5>
              <span className="bold underline-txt">
                Right to erasure ("right to be forgotten"):{" "}
              </span>{" "}
              Under certain conditions, the respondent has the right to receive
              from the Company deletion of their personal data, and the Company
              is obliged to delete them.
            </h5>
            <h5>
              <span className="bold underline-txt">
                {" "}
                Right to restriction of processing:
              </span>{" "}
              Under certain conditions, the subject has the right to obtain from
              the Company a restriction of processing of their personal data. In
              such cases, the relevant data will be marked and may only be
              processed for specific purposes.
            </h5>
            <h5>
              <span className="bold underline-txt">Right to object: </span>{" "}
              Under certain conditions, the subject has the right to object at
              any time to the processing of their personal data, for reasons
              related to their particular situation, or when personal data are
              processed for direct marketing purposes. They can request the
              Company to stop processing their personal data.
            </h5>
            <h5>
              <span className="bold underline-txt">
                Right to data portability:
              </span>{" "}
              Under certain conditions, the subject has the right to receive
              their personal data that they have provided to the Company in a
              structured, commonly used, and machine-readable format, and has
              the right to transmit those data to another data controller
              without hindrance from our Company. If the subject wishes to learn
              more or wants to exercise one or more of the aforementioned
              rights, they can contact the Company at the address: Osijek-Koteks
              d.d., Osijek, Šamačka 11, or at the email address:
              zop@osijek-koteks.hr. Furthermore, if the subject believes that
              their rights are not being respected, they have the right to lodge
              a complaint with the Personal Data Protection Agency.
            </h5>
            <h4 className="rules-header">Storage of personal data</h4>
            <h5>
              The personal data collected about the subject by the Company is
              kept in a secure environment. The personal data of the subjects
              are protected from unauthorized access, disclosure, use,
              alteration, or destruction by any organization or individual. The
              collected personal data for the aforementioned purposes will be
              stored for the duration necessary for those purposes. Personal
              data of the subjects will not be kept in a form that allows them
              to be identified for longer than Osijek-Koteks d.d. reasonably
              considers necessary for achieving the purpose for which they were
              collected or processed. Osijek-Koteks d.d. will retain certain
              personal data for the period prescribed by law or another
              regulation that obligates the data controller to retain data. If
              the subject has given consent to the Company, their personal data
              will be processed until the withdrawal of consent. If a
              well-founded objection to the processing of personal data based on
              legitimate interest is declared, the Company will not process the
              subject's personal data in the future. If a judicial,
              administrative, or extrajudicial procedure is initiated, personal
              data may be stored until the end of such a procedure, including
              any possible period for declaring legal remedies. Osijek-Koteks
              d.d. will retain certain personal data for the period prescribed
              by law or another regulation that obligates the data controller to
              retain data.
            </h5>
            <h4 className="rules-header">Recipients of personal data</h4>
            <h5>
              The Company may share the personal information of the subjects
              with trusted business partners who maintain the IT system of the
              Company or provide services on behalf of Osijek-Koteks d.d., for
              example, for finance, advertising, payment processing, delivery,
              and other services. It is the duty of these service providers,
              according to contractual rights and obligations, to use the data
              entrusted to them only in accordance with our guidelines and
              exclusively for the purpose we have strictly determined, with the
              obligation to adequately protect them and treat them as a business
              secret. In some cases, business partners providing services on
              behalf of or for Osijek-Koteks d.d. may process the subject's data
              outside the European Union. However, the contracts the Company
              enters into with such entities obligate them to handle the data by
              applying specific security measures in accordance with the
              regulations applicable in the European Union.
            </h5>
          </h4>
        </div>
      ) : (
        <div className="about-content visibility-filter">
          <h4 className="about-paragraph">
            <h4 className="rules-header">Pravila privatnosti</h4>
            <h5>
              Ova Pravila privatnosti objašnjavaju razloge i načine na koji
              tvrtka Osijek-Koteks d.d., Osijek, Šamačka 11, OIB: 44610694500
              (dalje u tekstu: Osijek-Koteks d.d.; Društvo) obrađuje osobne
              podatke. Osijek Koteks d.d. je usmjeren na zaštitu osobnih
              podataka ispitanika i njihovu obradu u skladu s Općom uredbom o
              zaštiti podataka (GDPR) i Zakonom o provedbi Opće uredbe o zaštiti
              podataka. Osijek Koteks d.d. je voditelj obrade osobnih podataka.
              Društvo ima službenika za zaštitu osobnih podataka kojega se može
              kontaktirati putem adrese e-pošte: zop@osijek-koteks.hr ili na
              adresu: Osijek-Koteks d.d., Osijek, Šamačka 11.
            </h5>
            <h4 className="rules-header">
              Svrhe u koje Društvo obrađuje osobne podatke
            </h4>
            <h5>
              Osobne podatke ispitanika Društvo obrađuje zbog toga što zakonski
              propisi nalažu određena postupanja ili zbog ispunjavanja ugovornih
              obveza ili zbog poduzimanja radnji prije sklapanja ugovora ili
              temeljem legitimnih interesa Društva, osim kada su od tih interesa
              jači interesi ili temeljna prava i slobode pojedinaca koji
              zahtijevaju zaštitu osobnih podataka.Ako osobne podatke Društvo ne
              može obrađivati temeljem navedenih pravnih osnova, zatražit će
              privolu ispitanika. Ako se obrada temelji na privoli, ispitanik
              ima pravo u bilo kojem trenutku povući privolu. O povlačenju
              privole potrebno je obavijestiti voditelja obrade na adresu
              e-pošte zop@osijek-koteks.hr ili na adresu: Osijek-Koteks d.d.,
              Osijek, Šamačka 11. Provedeno povlačenje privole ne utječe na
              zakonitost obrade na temelju privole prije povlačenja.Osobne
              podatke ispitanika Društvo obrađuje ako je to potrebno za
              sklapanje i ispunjenje prava i obveza koje proizlaze iz zakona,
              ugovora, u svrhe unaprjeđenja poslovanja i dr.Osobne podatke u
              pravilu Društvo prikuplja izravno od ispitanika ili kada podatke
              prikuplja nadzorom (npr. putem video nadzora).
            </h5>
            <h4 className="rules-header">Kategorije osobnih podataka</h4>
            <h5>
              Kod sklapanja ugovora, odnosno drugih pravnih poslova, Društvu su
              potrebni podaci kao što su ime, prezime, adresa, OIB, a radi
              ispunjenja obveze i usluga. Ponekad ispitanika Društvo traži i
              dodatne osobne podatke, npr. datum rođenja, spol, adresu za
              plaćanje, adresu za isporuku, financijske informacije ili neke
              druge podatke u slučaju kada ispitanik komunicira s Društvom
              telefonski ili e-poštom. Kada Društvo podatke prikuplja putem
              nadzora (npr. preko video nadzora), to radi u skladu sa zakonskim
              odredbama ili u opravdanim slučajevima u svrhu zaštite osobne
              sigurnosti osoba i imovine.
            </h5>
            <h4 className="rules-header">Prava ispitanika</h4>
            <h5>Prava ispitanika su sljedeća:</h5>
            <h5>
              <span className="bold underline-txt">Pravo na pristup:</span>{" "}
              spitanik ima pravo dobiti od Društva potvrdu o tome obrađuju li se
              njegovi osobni podaci te ako se takvi osobni podaci obrađuju,
              pristup tim osobnim podacima. Informacije o pristupu uključuju,
              između ostalog, svrhe obrade, kategorije osobnih podataka o kojima
              je riječ, primatelje ili kategorije primatelja kojima su osobni
              podaci bili ili će biti otkriveni. Međutim, to nije bezuvjetno
              pravo i interesi drugih pojedinaca mogu ograničiti pravo pristupa
              ispitanika. Ispitanik ima pravo dobiti presliku osobnih podataka
              koji se obrađuju. Za daljnje preslike koje se zatraže, Društvo
              može naplatiti razumnu naknadu na temelju administrativnih
              troškova.
            </h5>
            <h5>
              <span className="bold underline-txt">Pravo na ispravak:</span>{" "}
              Ispitanik ima pravo ishoditi od Društva ispravljanje svojih
              netočnih osobnih podataka. Uzimajući u obzir svrhe obrade,
              ispitanik ima pravo dopuniti nepotpune osobne podatke, između
              ostalog, i davanjem dodatne izjave.
            </h5>
            <h5>
              <span className="bold underline-txt">
                Pravo na brisanje ("pravo na zaborav"):{" "}
              </span>{" "}
              Pod određenim uvjetima, ispitanik ima pravo ishoditi od Društva
              brisanje svojih osobnih podataka, a Društvo ih je obvezno
              izbrisati.
            </h5>
            <h5>
              <span className="bold underline-txt">
                {" "}
                Pravo na ograničenje obrade:
              </span>{" "}
              Pod određenim uvjetima, ispitanik ima pravo ishoditi od Društva
              ograničenje obrade svojih osobnih podataka. U tom će slučaju
              odgovarajući podaci biti označeni i mogu biti obrađeni samo u
              određene svrhe.
            </h5>
            <h5>
              <span className="bold underline-txt">Pravo na prigovor: </span>{" "}
              Pod određenim uvjetima, ispitanik ima pravo na prigovor u bilo
              kojem trenutku na obradu svojih osobnih podataka, iz razloga koji
              se odnose na ispitanikovu konkretnu situaciju ili kada se osobni
              podaci obrađuju u cilju izravnog marketinga te može tražiti od
              Društva da više ne obrađuje njegove osobne podatke.
            </h5>
            <h5>
              <span className="bold underline-txt">
                Pravo na prenosivost podataka:
              </span>{" "}
              Pod određenim uvjetima, ispitanik ima pravo zaprimiti svoje osobne
              podatke koje je pružio Društvu u strukturiranom, uobičajeno
              upotrebljivom i strojno čitljivom obliku i ima pravo prenijeti te
              podatke drugom voditelju obrade, bez ometanja od strane našeg
              Društva.Ako ispitanik želi saznati više ili želi koristiti jedan
              ili više prethodno navedenih prava, može kontaktirati Društvo na
              adresu: Osijek-Koteks d.d., Osijek, Šamačka 11 ili na adresu
              e-pošte: zop@osijek-koteks.hr. Također, ukoliko ispitanik smatra
              da se njegova prava ne poštuju, ima pravo podnijeti pritužbu
              Agenciji za zaštitu osobnih podataka.
            </h5>
            <h4 className="rules-header">Čuvanje osobnih podataka</h4>
            <h5>
              Osobne podatke koje prikuplja o ispitaniku, Društvo čuva u
              sigurnom okruženju. Osobni podaci ispitanika su zaštićeni od
              neovlaštenog pristupa, otkrivanja, uporabe, izmjena ili uništenja
              od bilo koje organizacije ili pojedinca. Prikupljeni osobni podaci
              za prethodno navedene svrhe bit će pohranjeni u trajanju potrebnom
              za navedene svrhe. Osobni podaci ispitanika neće se čuvati u
              obliku koji omogućuje da bude identificiran duže nego što
              Osijek-Koteks d.d. razumno smatra da je neophodno za ostvarenje
              svrhe za koju su prikupljeni ili obrađeni. Osijek-Koteks d.d. će
              određene osobne podatke čuvati u razdoblju koje propisuje zakon
              odnosno drugi propis koji obvezuje voditelja obrade na čuvanje
              podataka. Ako je ispitanik dao privolu Društvu, njegove osobne
              podatke Društvo će obrađivati do povlačenja privole. Ako izjavi
              osnovani prigovor na obradu osobnih podataka temeljem legitimnog
              interesa, ispitanikove osobne podatke Društvo neće obrađivati u
              budućnosti. Ako je pokrenut sudski, upravni ili izvansudski
              postupak, osobni se podaci mogu pohraniti do kraja takvog
              postupka, uključujući i moguće razdoblje za izjavljivanje pravnih
              lijekova. Osijek-Koteks d.d. će određene osobne podatke čuvati u
              razdoblju koji propisuje zakon odnosno drugi propis koji obvezuje
              voditelja obrade na čuvanje podataka.
            </h5>
            <h4 className="rules-header">Primatelji osobnih podataka</h4>
            <h5>
              Osobne informacije ispitanika Društvo može dati pouzdanim
              poslovnim partnerima koji održavaju IT sustav Društva ili pružaju
              usluge u ime tvrtke Osijek-Koteks d.d. Na primjer, za potrebe
              financija, oglašavanja, obrade plaćanja, isporuke i drugih usluga.
              Dužnost je tih pružatelja usluga, sukladno ugovornim pravima i
              obvezama, koristiti podatke koji su im povjereni samo u skladu s
              našim smjernicama i isključivo u svrhu koju smo strogo odredili uz
              obvezu da ih primjereno zaštite i smatraju poslovnom tajnom. U
              nekim slučajevima, poslovni partneri koji pružaju usluge u ime ili
              za Osijek-Koteks d.d. mogu podatke ispitanika obraditi izvan
              Europske unije. Međutim, ugovori koje Društvo sklapa s takvim
              subjektima obvezuju ih da s podacima postupaju primjenjujući
              posebne sigurnosne mjere u skladu s propisima koji se primjenjuju
              u Europskoj uniji.
            </h5>
          </h4>
        </div>
      )}
    </Layout>
  )
}

export const query = graphql`
  {
    file(relativePath: { eq: "pp2.jpg" }) {
      childImageSharp {
        fluid(
          sizes: "(max-width: 300px) calc(100vw - 25px), (max-width: 600px) calc(100vw - 15px), 1300px"
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
export default About
